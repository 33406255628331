import { FieldConfig } from 'src/app/shared/components/dynamic-form/models/field-config.interface';
import { Validators } from '@angular/forms';
import { FORM_ENV_CONFIG } from '../form-env.config';
import { CustomValidators } from '../customValidator/customValidator';
import { PATTERNS } from '../customValidator/patterns';

export const CONFIG_KUNDE_FORM: FieldConfig[] = [
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    name: 'firma',
    label: '(Firma) Firma',
    placeholder: 'Firma',
    validationConfig: [
      {
        type: 'required',
        errorMessage: 'Firma ist ein Pflichtfeld.'
      }
    ]
  },
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    name: 'ansprechpartner',
    label: '(Firma) Ansprechpartner',
    placeholder: 'Ansprechpartner',
    validationConfig: [
      {
        type: 'required',
        errorMessage: 'Ansprechpartner ist ein Pflichtfeld.'
      }
    ]
  },
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    name: 'vorname',
    label: '(Privat) Vorname',
    placeholder: 'Vorname',
    validation: [Validators.required],
    validationConfig: [
      {
        type: 'required',
        errorMessage: 'Vorname ist ein Pflichtfeld.'
      }
    ]
  },
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    name: 'nachname',
    label: '(Privat) Nachname',
    placeholder: 'Nachname',
    validation: [Validators.required],
    validationConfig: [
      {
        type: 'required',
        errorMessage: 'Nachname ist ein Pflichtfeld.'
      }
    ]
  },
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    name: 'strasse',
    label: 'Straße',
    placeholder: 'Straße',
    validation: [Validators.required],
    validationConfig: [
      {
        type: 'required',
        errorMessage: 'Straße ist ein Pflichtfeld.'
      }
    ]
  },
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    name: 'plz',
    label: 'PLZ',
    placeholder: 'Postleitzahl',
    validation: [Validators.required, CustomValidators.pattern(PATTERNS.plz)],
    validationConfig: [
      {
        type: 'required',
        errorMessage: 'PLZ ist ein Pflichtfeld.'
      },
      {
        type: 'pattern',
        errorMessage: 'Format: 5 Ziffern (0-9)'
      }
    ]
  },
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    name: 'ort',
    label: 'Ort',
    placeholder: 'Ort',
    validation: [Validators.required],
    validationConfig: [
      {
        type: 'required',
        errorMessage: 'Ort ist ein Pflichtfeld.'
      }
    ]
  },
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    name: 'email',
    label: 'E-Mail',
    placeholder: 'E-Mail Adresse'
  },
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    name: 'telefon',
    label: 'Telefonnr.',
    placeholder: 'Telefonnummer'
  },
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    name: 'zus_telefon',
    label: 'Zus. Telefon',
    placeholder: 'Zus. Telefonnummer'
  },
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    name: 'zus_kontakt',
    label: 'Zus. Kontakt',
    placeholder: 'Zus. Kontakt'
  },
  {
    type: 'input',
    options: {
      inputtype: 'text',
      condition: 'permission_set === "admin"'
    },
    name: 'saldo',
    label: 'Saldo',
    placeholder: 'Saldo',
  }
];
