import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationService } from '@core/http/notification/notification.service';
import { Notification } from '@interfaces/notification.interface';
import { pipe, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import DateHelper from '../../../../helpers/date.helper';

@Component({
    selector: 'app-notification-element',
    templateUrl: './notification-element.component.html',
    styleUrls: ['./notification-element.component.scss']
  })

export class NotificationElementComponent implements OnInit {
    destroySubject$: Subject<void> = new Subject();
    @Input() notification: Notification;
    @Output() triggerReload: EventEmitter<any> = new EventEmitter();

    constructor(private notificationService: NotificationService) { }

    ngOnInit(): void {}

    getNotificationTime(): string {
        const date = new Date(Date.parse(this.notification.date));
        return DateHelper.notificationDate(date);
    }

    deleteNotification(event: MouseEvent): void {
        event.stopPropagation();
        this.notificationService.deleteNotification(this.notification._id)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((response: any) => {
            this.triggerReload.emit(true);
        });
    }

    ngOnDestory(): void {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }
}