import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) { }

  public openSnackBar(message: string, action: string, options: object = { duration: 2000 }) {
    this.snackBar.open(message, action, options);
  }
}
