import { ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef, ComponentRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FormButtonComponent } from '../form-button/form-button.component';
import { FormInputComponent } from '../form-input/form-input.component';
import { FormSelectComponent } from '../form-select/form-select.component';
import { FormRadioComponent } from '../form-radio/form-radio.component';
import { FormAutocompleteComponent } from '../form-autocomplete/form-autocomplete.component';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
import { FormButtonChooserComponent } from '../form-button-chooser/form-button-chooser.component';
import { FormTextareaComponent } from '../form-textarea/form-textarea/form-textarea.component';

const components = {
  button: FormButtonComponent,
  input: FormInputComponent,
  textarea: FormTextareaComponent,
  select: FormSelectComponent,
  radio: FormRadioComponent,
  autocomplete: FormAutocompleteComponent,
  buttonChooser: FormButtonChooserComponent
};

@Directive({
  selector: '[dynamicField]'
})
export class DynamicFieldDirective implements OnInit, Field {
  @Input()
  config: FieldConfig;

  @Input()
  group: FormGroup;

  component: ComponentRef<Field>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {}

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnChanges() {
    if (this.component) {
      this.component.instance.config = this.config;
      this.component.instance.group = this.group;
    }
  }

  ngOnInit() {
    if (!components[this.config.type]) {
      const supportedTypes = Object.keys(components).join(', ');
      throw new Error(
        `Trying to use an unsupported type (${this.config.type}).
        Supported types: ${supportedTypes}`
      );
    }
    const component = this.resolver.resolveComponentFactory<Field>(components[this.config.type]);
    this.component = this.container.createComponent(component);
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
  }
}
