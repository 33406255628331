import { Component, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';

@Component({
  selector: 'form-input',
  styles: ['mat-form-field {width: 90%; }'],
  template: `
    <mat-form-field
      [formGroup]="group">
      <mat-label>{{ config.label }}</mat-label>
      <input matInput color="primary"
        [attr.type]="config.options.inputtype"
        [attr.placeholder]="config.placeholder"
        [formControlName]="config.name" />
        <mat-error *ngIf="(group.controls[config.name].dirty || group.controls[config.name].touched || (group.controls[config.name].value !== null && group.controls[config.name].value !== undefined)) && group.controls[config.name].invalid && config.validationConfig">
          {{ getErrorMessage() }}
        </mat-error>
    </mat-form-field>
  `,
})
export class FormInputComponent implements Field {
  config: FieldConfig;
  group: FormGroup;

  getErrorMessage(): string {
    let str = '';
    this.config.validationConfig.forEach(el => {
      if (this.group.controls[this.config.name].errors[el.type]) {
        str = el.errorMessage;
        return;
      }
    });
    return str;
  }
}
