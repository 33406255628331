import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Kunde } from '@interfaces/kundenfahrzeug-interface';

import { environment } from '@env/environment';
import { SharedService } from '../shared/shared.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ICreateKundeResponse,
  IGetKundenResponse,
  IGetKundeResponse,
  IUpdateKundeResponse
} from '@interfaces/response.interface';

@Injectable({
  providedIn: 'root'
})

export class KundeService {

  url = environment.apiUrl;
  count = 0;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Access-Control-Allow-Origin': '*',
    })
  };

  constructor(
    private http: HttpClient,
  ) { }

  getAll(filter: object = {}, query = { skip: 0, limit: 20, sort: null, order: null}): Observable<Kunde[]> {
    let queryString = '';
    queryString = '?';
    for (const key of Object.keys(filter)) {
      queryString += `${key}=${filter[key]}&`;
    }
    queryString += 'limit=' + query.limit;
    queryString += '&skip=' + query.skip;
    queryString += query.sort ? '&sort=' + query.sort : '';
    queryString += query.order ? '&order=' + query.order : '';

    return this.http.get<IGetKundenResponse>(this.url + 'kunden' + queryString)
      .pipe(
        map((response: IGetKundenResponse) => {
          this.count = response.count;
          return response.kunden;
        })
      );
  }

  getOneKunde(kID): Observable<Kunde> {
    return this.http.get<IGetKundeResponse>(this.url + 'kunden/' + kID)
        .pipe(
            map((response: IGetKundeResponse) => {
              return response.kunde;
            })
        );
  }

  // UPDATE UND CREATE

  create(data): Observable<ICreateKundeResponse> {
    return this.http.post<ICreateKundeResponse>(this.url + 'kunden', data, {headers: {'Content-Type': 'application/json'}})
        .pipe(
            map((response: ICreateKundeResponse) => {
              return response;
            })
        );
  }

  update(data): Observable<IUpdateKundeResponse> {
    return this.http.patch<IUpdateKundeResponse>(this.url + 'kunden/' + data._id, data, {headers: {'Content-Type': 'application/json'}})
        .pipe(
            map((response: IUpdateKundeResponse) => {
              return response;
            })
        );
  }

  deleteKunde(id): Observable<any> {
    return this.http.delete(this.url + 'kunden/' + id)
        .pipe(
            map((response: any) =>  {
              return response;
            })
        );
  }

  restore(kundeId: string): Observable<IUpdateKundeResponse> {
    return this.http.patch<IUpdateKundeResponse>(this.url + 'kunden/' + kundeId + '/restore', null, {headers: {'Content-Type': 'application/json'}})
        .pipe(
            map((response: IUpdateKundeResponse) => {
              return response;
            })
        );
  }
}
