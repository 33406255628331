<mat-drawer-container autosize>
    <mat-drawer #drawer mode="side" disableClose="true" opened="true">
      
      <mat-nav-list>
        <a mat-list-item class="menu-trigger" (click)="toggleSidenav()">
          <mat-icon mat-list-icon>menu</mat-icon>
          <h4 mat-line *ngIf="isExpanded">Menü</h4>
        </a>
        <a 
        *ngFor="let item of items" 
        mat-list-item [routerLink]="item.route" 
        routerLinkActive="active" 
        [matTooltip]="item.tooltipText" 
        [matTooltipPosition]="'right'"
        matTooltipClass="nav-link-item"
        matTooltipShowDelay="200"
        [matTooltipDisabled]="isExpanded"
        (click)="isExpanded = false;">
          <mat-icon mat-list-icon>{{ item.icon }}</mat-icon>
          <h4 mat-line *ngIf="isExpanded">{{ item.text }}</h4>
        </a>
      </mat-nav-list>

    </mat-drawer>

    <mat-drawer-content>
        <router-outlet></router-outlet>
    </mat-drawer-content>
    
  </mat-drawer-container>