export const PATTERNS = {
  huAu: /(^$)|^((0[1-9]|10|11|12)\/[0-9]{2}$)/,
  kennzeichen: /(^$)|(^[A-Z]{1,2}\-[A-Z]{1,2} [0-9]{1,4}$)|(^[A-Z]{3}\-[A-Z]{1} [0-9]{1,4}$)|(^[A-Z]{3}\-[A-Z]{2} [0-9]{1,3}$)/,
  erstzul: /(^$)|(^([0-2][0-9]|3[0-1])\.(0[1-9]|10|11|12)\.(((19|20)[0-9]{2})|([0-9]{2}))$)/,
  dot: new RegExp(`^([0][1-9]|[1-4][0-9]|[5][0-3])([0-9]{1}|[0-9]{2})$`, 'gi'),
  fin: new RegExp(`^([0-9]|[A-H]|[J-N]|[P]|[R-Z]){17}$`),
  plz: new RegExp(`^[0-9]{5}$`),
  tsn: new RegExp(`^[A-Z0-9]{3,}`),
  hsn: new RegExp(`^[A-Z0-9]{4}$`),
  reifengroesse: /^[1-3][0-9]{2}\/[0-9][0,5](R|(ZR))[1-2][0-9](?:[\s]{1}[0-9a-zA-Z\s]{0,})?$/
};
