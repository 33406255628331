<div class="notification-element-container">
    <div class="notification-element-content-wrapper">
        <span class="notification-element-text">
            {{ notification.text }}
        </span>
        <span class="notification-element-time">
            {{ getNotificationTime() }}
        </span>
    </div>
    <div class="notification-element-action" (click)="deleteNotification($event)">
        <span class="notification-element-action-btn">Ok</span>
    </div>
</div>
<mat-divider></mat-divider>