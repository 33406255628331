import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
    private ideaUrl = environment.ideaApiUrl;  

    constructor(
      private http: HttpClient,
    ) { }

    getNotifications():Observable<any> {
      return this.http.get(this.ideaUrl + 'api/v1/notifications')
        .pipe(
          map((response: any) => {
            return response.notifications;
          })
        );
    }

    deleteNotification(id): Observable<any> {
      return this.http.delete(this.ideaUrl + 'api/v1/notifications/' + id)
        .pipe(
          map((response: any) => {
            return response;
          })
        );
    }

    deleteNotifications(): Observable<any> {
      return this.http.delete(this.ideaUrl + 'api/v1/notifications')
        .pipe(
          map((response: any) => {
            return response;
          })
        )
    }
}