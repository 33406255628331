import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticationService } from '@core/services/authentication.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective implements OnInit, OnDestroy {

  private _permission: string;
  private _permissionType: string;
  private _permissionArea: string;

  @Input() set appHasPermission(value: any) {
    this._permission = value;
  }
  @Input() set appHasPermissionArea(value: any) {
    this._permissionArea = value;
  };
  @Input() set appHasPermissionType(value: any) {
    this._permissionType = value;
  }

  stop$ = new Subject<void>();

  isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authService.permissions$.pipe(
      takeUntil(this.stop$)
    ).subscribe(permissions => {
      if (permissions[this._permissionArea][this._permissionType][this._permission]) {

        if (!this.isVisible){
          this.isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }

      } else {
        this.isVisible = false;
        this.viewContainerRef.clear();
      }
    });

  }

  ngOnDestroy() {
    this.stop$.next();
  }
}
