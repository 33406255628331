
<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button mat-icon-button (click)="toggleNavbar()">
      <mat-icon>menu</mat-icon>
    </button>
    <a class="navbar-brand" href="#" style="margin-left: 30px;">
      <img src="../../assets/img/schluessel.svg">
    </a>
    <h5>KfzApp</h5>
    <span class="toolbar-spacer"></span>
    <app-notification></app-notification>
    <div>
      <button mat-icon-button disableRipple style="width: auto" class="dropdown" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <mat-icon class="mat-18">account_circle</mat-icon>  {{ currentUser ? currentUser.vorname : '' }}
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" (click)="redirectTo('/usermanagement')" *appHasPermission="'get_any';area:'auth';type:'users'">Benutzerverwaltung</a>
          <!--<a class="dropdown-item" (click)="redirectTo('/admin/testfarm')" *ngIf="currentUser ? currentUser.permission_set === 'admin': false">Test Farm</a>
          <a class="dropdown-item" (click)="redirectTo('/admin/dbfunctions')" *ngIf="currentUser ? currentUser.permission_set === 'admin': false">Datenbank</a>
          <a class="dropdown-item" (click)="redirectTo('/admin/dataimport')" *ngIf="currentUser ? currentUser.permission_set === 'admin': false">Import</a>-->
          <a class="dropdown-item" (click)="logoutUser()">Logout</a>
        </div>
      </button>
    </div>    
    
  </mat-toolbar-row>
</mat-toolbar>
<!--
<nav class="navbar navbar-expand-lg navbar-dark primary-background-color">
    <button mat-mini-fab (click)="toggleNavbar()" color="warn" style="margin: 5px;">
      <mat-icon aria-label="Menu">menu</mat-icon>
    </button>
    <a class="navbar-brand" href="#">
      <img src="../../assets/img/schluessel.svg">
    </a>

    <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" [ngClass]="{'show': navbarOpen }">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/dashboard'" routerLinkActive="active">Dashboard</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/kunden'" routerLinkActive="active">Kunden</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/fahrzeuge'" routerLinkActive="active">Fahrzeuge</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/inspektion'" routerLinkActive="active">Inspektion</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/reifenlager'" routerLinkActive="active">Reifenlager</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/artikelliste'" routerLinkActive="active">Artikelliste</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/check'" routerLinkActive="active">Checks</a>
        </li>
      </ul>
      <ul class="navbar-nav navbar-right" style="justify-content: flex-end;">
        <app-notification></app-notification>
        <li class="nav-item dropdown">
          <a class="nav-link" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <mat-icon class="mat-18">account_circle</mat-icon>  {{ currentUser ? currentUser.vorname : '' }}
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" (click)="redirectTo('/usermanagement')" *ngIf="currentUser ? (currentUser.permission_set === 'admin' || currentUser.permission_set === 'chef'): false">Benutzerverwaltung</a>
            <a class="dropdown-item" (click)="redirectTo('/admin/testfarm')" *ngIf="currentUser ? currentUser.permission_set === 'admin': false">Test Farm</a>
            <a class="dropdown-item" (click)="redirectTo('/admin/dbfunctions')" *ngIf="currentUser ? currentUser.permission_set === 'admin': false">Datenbank</a>
            <a class="dropdown-item" (click)="redirectTo('/admin/dataimport')" *ngIf="currentUser ? currentUser.permission_set === 'admin': false">Import</a>
            <a class="dropdown-item" (click)="logoutUser()">Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
-->