import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import * as _ from 'underscore';

import { FieldConfig } from '../../models/field-config.interface';
import { FormValidationConfig } from '../../models/form-validation.config';
import { DynamicFormLayoutConfig } from '../../models/dynamic-form-layout-config.interface';

@Component({
  exportAs: 'dynamicForm',
  selector: 'dynamic-form',
  template: `
    <form
      class="dynamic-form"
      [formGroup]="form"
      (ngSubmit)="handleSubmit($event)">
      <div class="row" style="justify-content: center" *ngFor="let row of layoutConfig">
        <div class="col" *ngFor="let field of row.row;">
          <ng-template
            dynamicField
            style="width: 100%"
            [config]="field"
            [group]="form">
          </ng-template>
        </div>
      </div>
    </form>
  `,
  styles: [
    `
    form {max-width: 100%}
    `,
]
})

export class DynamicFormComponent implements OnInit, OnChanges {

  @Input() config: FieldConfig[];
  @Input() validation: FormValidationConfig;
  @Input() layout: DynamicFormLayoutConfig;
  @Input() customConfig: any;

  @Input() data: any = {};

  formDirty = false;
  startValue = null;

  form: FormGroup;
  layoutConfig;

  @Output()
  submitted: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  changedValue: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  get controls() { return this.config.filter(({type}) => type !== 'button'); }
  get changes() { return this.form.valueChanges; }
  get valid() { return this.form.valid; }
  get value() { return this.form.value; }

  constructor(private fb: FormBuilder) {

  }

  ngOnInit() {
    this.form = this.createGroup();
    if (this.data !== {}) {
      this.form.patchValue(this.data);
    }
    if (this.validation) {
      // #TODO FUNCTION => SUBSCRIBE TO CONTROLS
      this.setValidatorFunctions();
    }
    if (this.layout) {
      this.generateConfigWithLayout();
    }
    this.startValue = this.form.value;
    this.form.valueChanges.subscribe(value => {
      this.changedValue.emit(this.form);
    });
  }

  generateConfigWithLayout() {
    const layoutConfig = [];
    for (const row of this.layout.rows) {
      const items = [];
      for (const entry of row.names) {
        for (const conf of this.config) {
          if (entry === (conf ? conf.name : null)) {
            items.push(conf);
          }
        }
      }
      layoutConfig.push({row: items});
    }
    this.layoutConfig = layoutConfig;
  }

  ngOnChanges(changes) {
    if (this.form) {
      const controls = Object.keys(this.form.controls);
      const configControls = this.controls.map((item) => item.name);

      controls
        .filter((control) => !configControls.includes(control))
        .forEach((control) => this.form.removeControl(control));

      configControls
        .filter((control) => !controls.includes(control))
        .forEach((name) => {
          const config = this.config.find((control) => control.name === name);
          this.form.addControl(name, this.createControl(config));
        });
        // Check for new Input
        // #TODO: Check if unsaved changes
      if (Object.keys(changes.data.currentValue).length === 0) {
          this.form.reset();
          this.formDirty = false;
          this.startValue = this.form.value;
        } else if (changes.data) {
          this.form.reset();
          this.form.patchValue(this.data);
          this.startValue = this.form.value;
          this.formDirty = false;
        }
    }
  }

  setValidatorFunctions() {
    const validators = this.validation.dynamicValidators;
    for (const validatorOpts of validators) {
      for (const changeValue of validatorOpts.valueChanges) {
        this.form.get(changeValue).valueChanges.subscribe(val => {
          if (
            val
              ? typeof val === 'string'
                ? val.trim() !== ''
              : val.toString().trim() !== ''
                ? true
              : false
            : typeof val !== 'object'
              ? val.toString().trim() !== ''
            : false) {
            for (const setValidatorControl of validatorOpts.setValidators) {
              if (setValidatorControl.condition !== null && setValidatorControl.condition !== undefined) {
                if (setValidatorControl.condition === val) {
                  if (setValidatorControl.unsetValues) {
                    for (const unsetVal of setValidatorControl.unsetValues) {
                      this.form.controls[unsetVal].setValue(null);
                      this.form.controls[unsetVal].updateValueAndValidity({emitEvent: false});
                    }
                  }
                  this.form.controls[setValidatorControl.name].setValidators(setValidatorControl.validators);
                  this.form.controls[setValidatorControl.name].updateValueAndValidity({emitEvent: false});
                }
              } else {
                this.form.controls[setValidatorControl.name].setValidators(setValidatorControl.validators);
                this.form.controls[setValidatorControl.name].updateValueAndValidity({emitEvent: false});
              }
            }
            for (const unsetValidatorControl of validatorOpts.unsetValidators){
              this.form.controls[unsetValidatorControl].clearValidators();
              this.form.controls[unsetValidatorControl].updateValueAndValidity({emitEvent: false});
            }
          }
        });
      }
    }
  }

  createGroup() {
    const group = this.fb.group({});
    this.config.forEach(control => group.addControl(control.name, this.createControl(control)));
    return group;
  }

  createControl(config: FieldConfig) {
    const { disabled, validation, value } = config;
    return this.fb.control({ disabled, value }, validation);
  }

  handleSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.submitted.emit(this.value);
  }

  setDisabled(name: string, disable: boolean) {
    if (this.form.controls[name]) {
      const method = disable ? 'disable' : 'enable';
      this.form.controls[name][method]();
      return;
    }

    this.config = this.config.map((item) => {
      if (item.name === name) {
        item.disabled = disable;
      }
      return item;
    });
  }

  setValue(name: string, value: any) {
    this.form.controls[name].setValue(value, {emitEvent: true});
  }
}
