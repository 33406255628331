import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { ClipboardModule } from '@angular/cdk/clipboard';

  @NgModule({
    declarations: [],
    imports: [
      CommonModule,
      MatTabsModule,
      MatBadgeModule,
      MatAutocompleteModule,
      MatFormFieldModule,
      MatInputModule,
      MatSelectModule,
      MatExpansionModule,
      MatCardModule,
      MatGridListModule,
      MatButtonModule,
      MatToolbarModule,
      MatIconModule,
      MatSidenavModule,
      MatListModule,
      MatDialogModule,
      MatRadioModule,
      MatTableModule,
      MatSortModule,
      MatPaginatorModule,
      MatProgressBarModule,
      MatProgressSpinnerModule,
      MatCheckboxModule,
      MatDividerModule,
      MatTabsModule,
      MatButtonToggleModule,
      MatSlideToggleModule,
      MatTooltipModule,
      MatSnackBarModule,
      MatChipsModule,
      ClipboardModule,
    ],
    exports: [
      MatTabsModule,
      MatBadgeModule,
      MatAutocompleteModule,
      MatFormFieldModule,
      MatInputModule,
      MatSelectModule,
      MatExpansionModule,
      MatCardModule,
      MatGridListModule,
      MatButtonModule,
      MatToolbarModule,
      MatIconModule,
      MatSidenavModule,
      MatListModule,
      MatDialogModule,
      MatRadioModule,
      MatTableModule,
      MatSortModule,
      MatPaginatorModule,
      MatProgressBarModule,
      MatProgressSpinnerModule,
      MatCheckboxModule,
      MatDividerModule,
      MatTabsModule,
      MatButtonToggleModule,
      MatSlideToggleModule,
      MatTooltipModule,
      MatSnackBarModule,
      MatChipsModule,
      ClipboardModule,
    ],
    providers: [{provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}],
    bootstrap: [],
    entryComponents: []
  })

  export class MaterialModule {}