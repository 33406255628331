import {Component, ViewContainerRef, OnInit, OnDestroy} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {startWith, debounceTime, switchMap, map, takeUntil} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';

@Component({
  selector: 'form-autocomplete',
  styles: ['mat-form-field {width: 90%}'],
  template: `
  <mat-form-field [formGroup]="group">
  <input type="text" matInput [matAutocomplete]="auto" [name]="config.name" [placeholder]="config.placeholder" [formControlName]="config.name">
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnWrapper()">
    <mat-option *ngFor="let option of (filteredOptions | async)" [value]="option">
      {{ getOptionDisplay(option) }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
  `,
})
export class FormAutocompleteComponent implements OnInit, OnDestroy, Field {
  config: FieldConfig;
  group: FormGroup;

  filteredOptions: Observable<any>;
  destroySubject$: Subject<void> = new Subject();

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.filteredOptions = this.group.controls[this.config.name].valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      switchMap(value => this.http.get(this.config.options.data.url
        + (this.config.options.data.querystring && value.length > 0
          ? this.config.options.data.querystring + value : ''))
          .pipe(
              takeUntil(this.destroySubject$),
              map(res => {
                console.log(res);
                return res[this.config.options.optionAccessor];
              })
          )
      )
    );
  }

  getOptionDisplay(option): string {
    let str = '';
    for (const displayOpt of this.config.options.optionDisplay) {
      for (const opt of displayOpt.keys) {
        if (option[opt]) {
            str = str + option[opt] + ((displayOpt.keys.indexOf(opt) < (displayOpt.keys.length - 1)
                && displayOpt.keys.indexOf(opt) !== -1) ? displayOpt.seperator : '');
        }
      }
      if (str !== '') {
        break;
      }
    }
    return str;
  }

  displayFn(val) {
    const str = val ? this.getOptionDisplay(val) : undefined;
    return str;
  }

  displayFnWrapper() {
    return (subject) => this.displayFn(subject);
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
