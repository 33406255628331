import { Validators } from '@angular/forms';

export const CONFIG_KUNDE_FORM_VALIDATION = {
  dynamicValidators: [
    {
      valueChanges: ['firma', 'ansprechpartner'],
      setValidators: [
        {
          name: 'firma',
          validators: [Validators.required]
        },
        {
          name: 'ansprechpartner',
          validators: [Validators.required]
        }
      ],
      unsetValidators: [
        'vorname', 'nachname'
      ]
    },
    {
      valueChanges: ['vorname', 'nachname'],
      setValidators: [
        {
          name: 'vorname',
          validators: [Validators.required]
        },
        {
          name: 'nachname',
          validators: [Validators.required]
        }
      ],
      unsetValidators: [
        'firma', 'ansprechpartner'
      ]
    },
  ]
};
