import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Fahrzeug } from '@interfaces/kundenfahrzeug-interface';
import {
  ICreateFahrzeugResponse,
  IGetFahrzeugeResponse,
  IGetFahrzeugResponse,
  IUpdateFahrzeugResponse
} from '@interfaces/response.interface';
import {environment} from '@env/environment';

import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SnackBarService } from '@shared/services/messages/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class FahrzeugService {

  url = environment.apiUrl;
  count = 0;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private _snackbarService: SnackBarService
  ) { }

  // GET-Methoden

  getAll(filter: object = {}, query = { skip: 0, limit: 20, sort: null, order: null}): Observable<Fahrzeug[]> {
    let queryString = '';
    queryString = '?';
    for (const key of Object.keys(filter)) {
      queryString += key + '=' + filter[key] + '&';
    }
    queryString += 'limit=' + query.limit;
    queryString += '&skip=' + query.skip;
    queryString += query.sort ? '&sort=' + query.sort : '';
    queryString += query.order ? '&order=' + query.order : '';

    return this.http.get<IGetFahrzeugeResponse>(this.url + 'fahrzeuge' + queryString)
        .pipe(
            map((response: IGetFahrzeugeResponse) => {
              this.count = response.count;
              return response.fahrzeuge;
            })
        );
  }

  getFahrzeugeByKundeId(kId: string): Observable<Fahrzeug[]> {
    return this.http.get(this.url + 'fahrzeuge?kunde=' + kId)
        .pipe(
            map((response: IGetFahrzeugeResponse) => {
              return response.fahrzeuge;
            })
        );
  }

  getOneFahrzeug(id): Observable<Fahrzeug> {
    return this.http.get<IGetFahrzeugResponse>(this.url + 'fahrzeuge/' + id)
        .pipe(
            map((response: IGetFahrzeugResponse) => {
              return response.fahrzeug;
            })
        );
  }

  // UPDATE UND CREATE

  update(data): Observable<IUpdateFahrzeugResponse> {
    return this.http.patch<IUpdateFahrzeugResponse>(
        this.url + 'fahrzeuge/' + data._id,
        data,
        {headers: {'Content-Type': 'application/json'}}
        )
        .pipe(
            map((response: IUpdateFahrzeugResponse) => {
              return response;
            })
        );
  }

  updateMany(data): Observable<IUpdateFahrzeugResponse> {
    return this.http.post<IUpdateFahrzeugResponse>(
      this.url + 'fahrzeuge/update',
        { data },
        {headers: {'Content-Type': 'application/json'}}
    )
    .pipe(
      map((response: IUpdateFahrzeugResponse) => {
        return response;
      })
    );
  }

  create(data): Observable<ICreateFahrzeugResponse> {
    return this.http.post<ICreateFahrzeugResponse>(this.url + 'fahrzeuge', data, {headers: {'Content-Type': 'application/json'}})
        .pipe(
            map((response: ICreateFahrzeugResponse) => {
              return response;
            })
        );
  }

  // DELETE
  // #TODO: Nicht löschen sondern als gelöscht markieren

  deleteFahrzeug(id): Observable<any> {
    return this.http.delete(this.url + 'fahrzeuge/' + id);
  }

  restore(fahrzeugId: string): Observable<IUpdateFahrzeugResponse> {
    return this.http.patch<IUpdateFahrzeugResponse>(this.url + 'fahrzeuge/' + fahrzeugId + '/restore', null, {headers: {'Content-Type': 'application/json'}})
        .pipe(
            map((response: IUpdateFahrzeugResponse) => {
              return response;
            })
        );
  }


  // TODO: Separate API Funktion
  removeKunde(fahrzeug: Fahrzeug): any {
    const fahrzeugToUpdate = fahrzeug;
    fahrzeugToUpdate.kunde = null;
    this.update(fahrzeugToUpdate);
  }

  uploadImage(file: File, fileType: string, metadata: any = null) {
    const formData = new FormData();
    formData.append('document', file);
    formData.append('metadata', JSON.stringify(metadata));
    return this.http.post(this.url + 'document/' + fileType, formData)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => {
          this._snackbarService.openSnackBar(error.error.message ?? 'Fehler beim Hochladen', 'OK', { duration: 5000 });
          const errMsg = {
            isError: true,
            message: "Fehler beim Hochladen des Dokuments"
          }
          return of(errMsg);
        })
      );
  }

  addConnectedEntity(processNr, fzId) {
    return this.http.post(`${this.url}document/${processNr}/fahrzeug/${fzId}`, {})
      .pipe(
        map((response: any) => {
          return response
        }),
        catchError(error => {
          return of(error);
        })
      );
  }

  getRelatedDocuments(fzId) {
    return this.http.get(`${this.url}document/fahrzeug/${fzId}`)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => {
          return of(error);
        })
      );
  }

  getImageToDisplay(filename: string): Promise<{url: SafeResourceUrl, arrayBuffer: Uint8Array, blob: any}> {
    return lastValueFrom(this.http.get(`${this.url}document/file/${filename}`, { responseType: 'blob'})
      .pipe(
        map(async (response: any) => {
          var blob = response;
          const urlToBlob = window.URL.createObjectURL(response);
          var buffer = await new Uint8Array(await new Response(blob).arrayBuffer());
          console.log(urlToBlob);
          console.log(buffer);

          return { url: this.sanitizer.bypassSecurityTrustResourceUrl(urlToBlob), arrayBuffer: buffer, blob: urlToBlob };
        }),
        catchError(error => {
          if (error.status === 404) {
            alert('Die Bilddatei wurde auf dem Server nicht gefunden. Bitte lösche den Kfz-Schein und füge ihn erneut hinzu.');
          }
          return of(error);
        })
      ))
  }

  deleteDocument(filename: string) {
    return this.http.delete(`${this.url}document/${filename}`)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => {
          return of(error);
        })
      );
  }

  uploadToKonfuzio(processNr) {
    return this.http.post(`${this.url}fzscan/`, { file: processNr })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => {
          this._snackbarService.openSnackBar(error.error.message ?? 'Fehler beim Hochladen zum externen Service', 'OK', { duration: 5000 });
          const errMsg = {
            isError: true,
            message: "Fehler beim Hochladen des Dokuments zum Auslesen der Daten"
          }
          return of(errMsg);
        })
      );
  }

  getExtractionFromKonfuzio(processNr) {
    console.log("CHECK");
    return this.http.get(`${this.url}fzscan/${processNr}`)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => {
          this._snackbarService.openSnackBar(error.error.message ?? 'Fehler beim Ermitteln der Extraktionsergebnisse', 'OK', { duration: 10000 });
          const errMsg = {
            isError: true,
            message: "Fehler beim Ermitteln der Extraktionsergebnisse"
          }
          return of(errMsg);
        })
      );
  }
}
