import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonChooserComponent } from '../button-chooser/button-chooser.component';
import { DynamicFormComponent } from './containers/dynamic-form/dynamic-form.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { FormButtonComponent } from './components/form-button/form-button.component';
import { FormRadioComponent } from './components/form-radio/form-radio.component';
import { FormAutocompleteComponent } from './components/form-autocomplete/form-autocomplete.component';
import { MaterialModule } from '../../../material.module';
import { DynamicFieldDirective } from './components/dynamic-field/dynamic-field.directive';
import { OpenDialogDirective } from './directives/open-dialog/open-dialog.directive';
import { DynamicFormDialogComponent } from './containers/dynamic-form-dialog/dynamic-form-dialog.component';
import { FormButtonChooserComponent } from './components/form-button-chooser/form-button-chooser.component';
import { FormTextareaComponent } from './components/form-textarea/form-textarea/form-textarea.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MaterialModule],
  declarations: [
    DynamicFormComponent,
    ButtonChooserComponent,
    DynamicFormDialogComponent,
    FormInputComponent,
    FormSelectComponent,
    FormButtonComponent,
    FormRadioComponent,
    FormAutocompleteComponent,
    OpenDialogDirective,
    DynamicFieldDirective,
    FormButtonChooserComponent,
    FormTextareaComponent
  ],
  entryComponents: [
    FormInputComponent,
    FormSelectComponent,
    FormButtonComponent,
    FormRadioComponent,
    FormAutocompleteComponent,
    FormButtonChooserComponent,
    FormTextareaComponent
  ],
  exports: [DynamicFormComponent, OpenDialogDirective, ButtonChooserComponent, DynamicFieldDirective]
})

export class DynamicFormModule {}
