export const CONFIG_KUNDE_FORM_LAYOUT = {
  rows: [
    {
      cols: 1,
      names: ['kundennr']
    },
    {
      cols: 2,
      names: ['firma', 'ansprechpartner'],
    },
    {
      cols: 2,
      names: ['vorname', 'nachname'],
    },
    {
      cols: 1,
      names: ['strasse'],
    },
    {
      cols: 2,
      names: ['plz', 'ort'],
    },
    {
      cols: 2,
      names: ['telefon', 'zus_telefon'],
    },
    {
      cols: 1,
      names: ['email']
    },
    {
      cols: 1,
      names: ['submit']
    }
  ]
};
