import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input() isExpanded: boolean;

  items = [
    {
      route: '/dashboard',
      tooltipText: 'Dashboard',
      icon: 'dashboard',
      text: 'Dashboard'
    },
    {
      route: '/kunden',
      tooltipText: 'Kunden',
      icon: 'person',
      text: 'Kunden'
    },
    {
      route: '/fahrzeuge',
      tooltipText: 'Fahrzeuge',
      icon: 'directions_car',
      text: 'Fahrzeuge'
    },
    {
      route: '/inspektion',
      tooltipText: 'Inspektion',
      icon: 'build',
      text: 'Inspektion'
    },
    {
      route: '/reifenlager',
      tooltipText: 'Reifenlager',
      icon: 'corporate_fare',
      text: 'Reifenlager'
    },
    {
      route: '/artikelliste',
      tooltipText: 'Artikel',
      icon: 'source',
      text: 'Artikel'
    },
    {
      route: '/check',
      tooltipText: 'Checks',
      icon: 'checklist',
      text: 'Checks'
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

  toggleSidenav(): void {
    this.isExpanded = !this.isExpanded;
  }

}
