import { NotificationDateMap } from './notification-date.map';

export default class DateHelper {
  static getWeekOfYear(dowOffset: number = 0): number {
    var currentDate = new Date();

    dowOffset = typeof(dowOffset) == 'number' ? dowOffset : 0; //default dowOffset to zero
    var newYear = new Date(currentDate.getFullYear(),0,1);
    var day = newYear.getDay() - dowOffset; //the day of week the year begins on
    day = (day >= 0 ? day : day + 7);
    var daynum = Math.floor((currentDate.getTime() - newYear.getTime() - 
    (currentDate.getTimezoneOffset()-newYear.getTimezoneOffset())*60000)/86400000) + 1;
    var weeknum;
    //if the year starts before the middle of a week
    if(day < 4) {
      weeknum = Math.floor((daynum+day-1)/7) + 1;
      if(weeknum > 52) {
        var nYear = new Date(currentDate.getFullYear() + 1,0,1);
        var nday = nYear.getDay() - dowOffset;
        nday = nday >= 0 ? nday : nday + 7;
        /*if the next year starts before the middle of
           the week, it is week #1 of that year*/
        weeknum = nday < 4 ? 1 : 53;
      }
    }
    else {
      weeknum = Math.floor((daynum+day-1)/7);
    }
    return weeknum;
  }

  static currentDate(): Date {
    return new Date();
  }

  static getCurrentYear(): number {
    const date = new Date();
    return date.getFullYear();
  }

  static getCurrentMonth(): number {
    const date = new Date();
    return date.getMonth() + 1 ;
  }

  static notificationDate(date: Date): string {
    /**
     * UPDATE 14.12.2022: weeks und months grob hinzugefügt
     */
    const now = new Date(Date.now());
    const diffInMillis = now.getTime() - date.getTime();
    const diffInSeconds = Math.floor(diffInMillis / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = now.getDate() - date.getDate();
    const diffInWeeks = diffInDays / 7;
    const diffInMonths = diffInDays / 30;
    let stringWithVal = '';
    let valIsOne = false;
    if (diffInMinutes < 1) {
      return NotificationDateMap.recent;
    } else if (diffInHours < 1) {
      stringWithVal = NotificationDateMap.minutes_ago.replace('<X>', diffInMinutes.toString());
      if (diffInMinutes < 2) { valIsOne = true;}
    } else if (diffInDays < 1) {
      stringWithVal = NotificationDateMap.hours_ago.replace('<X>', diffInHours.toString());
      if (diffInHours < 2) { valIsOne = true;}
    } else if (diffInWeeks < 1) {
      stringWithVal = NotificationDateMap.days_ago.replace('<X>', diffInDays.toString());
      if (diffInDays < 2) { valIsOne = true;}
    } else if (diffInMonths < 1) {
      stringWithVal = NotificationDateMap.weeks_ago.replace('<X>', diffInWeeks.toString());
      if (diffInWeeks < 2) { valIsOne = true; }
    } else {
      stringWithVal = NotificationDateMap.months_ago.replace('<X>', diffInMonths.toString());
      if (diffInMonths < 2) { valIsOne = true; }
    }
    if (valIsOne) {
      stringWithVal = stringWithVal.replace(/<.*>/, '');
    } else {
      stringWithVal = stringWithVal.replace(/<|>/g, '');
    }
    return stringWithVal;
  }
}