import { Component, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';

@Component({
  selector: 'form-radio',
  styles: ['.radio-group {display: flex; flex-direction: column;}'],
  template: `
  <div [formGroup]="group" class="radio-group">
    <label [id]="config.id">{{ config.label }}</label>
    <mat-radio-group
      [attr.aria-labelledby]="config.id"
      [formControlName]="config.name">
        <mat-radio-button *ngFor="let radio of config.options" [value]="radio.value" style="margin-left: 0.5em" [checked]="radio.default">{{ radio.label }}</mat-radio-button>
    </mat-radio-group>
  </div>
  `,
})
export class FormRadioComponent implements Field{
  config: FieldConfig;
  group: FormGroup;
}
