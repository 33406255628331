import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Notification } from '@interfaces/notification.interface';
import { NotificationService } from '@core/http/notification/notification.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from '@env/environment';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  destroySubject$: Subject<void> = new Subject();
  notifications$: Observable<Notification[]>;

  notificationsCount = 0;
  notificationReloadInterval = 300; // In Seconds
  private notificationTrigger$ = new BehaviorSubject<boolean>(false);

  ideaUrl = environment.ideaUrl;

  constructor(private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.notifications$ = this.notificationTrigger$
      .pipe(
        takeUntil(this.destroySubject$),
        switchMap(() => {
          return this.notificationService.getNotifications()
            .pipe(
              tap(val => this.notificationsCount = val?.length)
            )
        })
      );
    
      setInterval(() => { this.reloadNotifications() }, this.notificationReloadInterval * 1000);
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  reloadNotifications(event: MouseEvent = null): void {
    if (event) {
      event.stopPropagation();
    }
    this.notificationTrigger$.next(false);
  }

  deleteAllNotifications(event): void {
    event.stopPropagation();
    this.notificationService.deleteNotifications()
      .pipe(
        takeUntil(this.destroySubject$)
      ).subscribe(() => {
        this.reloadNotifications();
      });
  }

}
