import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import { AuthenticationService } from '@core/services/authentication.service';
import {first, map, take} from "rxjs/operators";

/**
 * Eingeführt in Version 1.1
 * Status: Experimentell
 */

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public authService: AuthenticationService,
    public router: Router,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (
          this.authService.isLoggedIn !== true
      ) {
          const ssoToken: string = next.queryParamMap.get('ssoToken');
          if (!ssoToken) {
              this.authService.login();
          }
          this.authService.getTokenFromSso(ssoToken)
              .pipe(take(1))
              .subscribe(token => {
                  return this.authService.verifyTokenLocal(token)
                      .pipe(take(1))
                      .subscribe(value => {
                          this.router.navigate(['/dashboard']);
                      });
              });
      } else {
          let permission;
          try {
              return this.authService.getCurrentUser().pipe(
                  take(1),
                  map(user => {
                      permission = user?.permission_set;
                      if (/*(next.url[0].path === 'admin' && permission !== 'admin')
                          || */(next.url[0].path === 'usermanagement' && !permission.auth.users.get_any)
                      ) {
                          this.router.navigate(['dashboard']);
                          return false;
                      }
                      return true;
                  }),
                  first()
              );
          } catch (err) {
              return of(false);
          }
      }
  }
}
