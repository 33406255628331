import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Extractions, FzscanAnswer } from '@interfaces/fzscan.interface';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FzscanService {

  url = environment.apiUrl;
  count = 0;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  
  constructor(
    private http: HttpClient
  ) { }

  getPartialImages(processNr): Observable<{ message: string, partialImages: any }> {
    return this.http.get(this.url + 'fzscan/' + processNr + '/partial', this.httpOptions)
      .pipe(
        map((result: { message: string, partialImages: any }) => {
          return result;
        })
      );
  }

  
}
