import { AbstractControl, ValidatorFn} from '@angular/forms';
export class CustomValidators {

  public static pattern(reg: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const value = control.value ? control.value as string : '';
      return value.match(reg) ? null : { pattern: { value }};
    };
  }
}
