import { Validators } from '@angular/forms';
import { FieldConfig } from 'src/app/shared/components/dynamic-form/models/field-config.interface';
import { environment } from '../../../../environments/environment';
import { CustomValidators } from '../customValidator/customValidator';
import { PATTERNS } from '../customValidator/patterns';

export const CONFIG_FAHRZEUG_FORM: FieldConfig[] = [
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    validation: [Validators.required],
    validationConfig: [
      {
        type: 'required',
        errorMessage: 'Hersteller ist ein Pflichtfeld.'
      }
    ],
    name: 'hersteller',
    label: 'Hersteller',
    placeholder: 'Hersteller'
  },
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    validation: [Validators.required],
    validationConfig: [
      {
        type: 'required',
        errorMessage: 'Modell ist ein Pflichtfeld.'
      }
    ],
    name: 'modell',
    label: 'Modell',
    placeholder: 'Modell'
  },
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    name: 'erstzul',
    label: 'Erstzulassung',
    placeholder: 'Erstzulassung',
    validation: [Validators.required, CustomValidators.pattern(PATTERNS.erstzul)],
    validationConfig: [
      {
        type: 'required',
        errorMessage: 'Erstzulassung ist ein Pflichtfeld'
      },
        {
          type: 'pattern',
          errorMessage: 'Format: TT.MM.JJJJ'
        }
      ]
  },
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    name: 'schluesselnr_2_1',
    label: 'Schlüsselnummer 2.1',
    placeholder: 'Schlüsselnummer 2.1',
    validation: [Validators.required, CustomValidators.pattern(PATTERNS.hsn)],
    validationConfig: [
      {
        type: 'required',
        errorMessage: 'Schl.Nr. 2.1 ist ein Pflichtfeld.'
      },
      {
        type: 'pattern',
        errorMessage: 'Genau 4 Zeichen eingeben.'
      }
    ]
  },
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    name: 'schluesselnr_2_2',
    label: 'Schlüsselnummer 2.2',
    placeholder: 'Schlüsselnummer 2.2',
    validation: [Validators.required, CustomValidators.pattern(PATTERNS.tsn)],
    validationConfig: [
      {
        type: 'required',
        errorMessage: 'Schl.Nr. 2.2 ist ein Pflichtfeld.'
      },
      {
        type: 'pattern',
        errorMessage: 'Mindestens 3 Zeichen eingeben.'
      }
    ]
  },
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    name: 'fahrgestellnr',
    label: 'Fahrgestellnummer',
    placeholder: 'Fahrgestellnummer',
    validation: [Validators.required, CustomValidators.pattern(PATTERNS.fin)],
    validationConfig: [
      {
        type: 'required',
        errorMessage: 'FIN ist ein Pflichtfeld.'
      },
      {
        type: 'pattern',
        errorMessage: 'Format: 17 Zeichen; kein I, O und Q'
      }
    ]
  },
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    name: 'kennzeichen',
    label: 'Kennzeichen',
    placeholder: 'Kennzeichen',
    validation: [Validators.required, CustomValidators.pattern(PATTERNS.kennzeichen)],
    validationConfig: [
        {
          type: 'required',
          errorMessage: 'Kennzeichen ist ein Pflichtfeld.'
        },
        {
          type: 'pattern',
          errorMessage: 'Format: KFZ-W 123'
        }
      ]
  },
  {
    type: 'input',
    options: {
      inputtype: 'number'
    },
    name: 'kmstand',
    label: 'Kilometerstand',
    placeholder: 'Kilometerstand'
  },
  {
    type: 'input',
    options: {
      inputtype: 'text'
    },
    name: 'hu_au',
    label: 'HU/AU',
    placeholder: 'HU/AU',
    validation: [CustomValidators.pattern(PATTERNS.huAu)],
    validationConfig: [
        {
          type: 'required',
          errorMessage: 'HU/AU ist ein Pflichtfeld.'
        },
        {
          type: 'pattern',
          errorMessage: 'Format: MM/JJ'
        }
      ]
  },
  {
    type: 'autocomplete',
    name: 'kunde',
    label: 'Kunde',
    placeholder: 'Kunde',
    options: {
      optionAccessor: 'kunden',
      optionDisplay: [
        {
          keys: ['nachname', 'vorname'],
          seperator: ', ',
        },
        {
          keys: ['firma', 'ansprechpartner'],
          seperator: ' | ',
        }
      ],
      data: {
        url: environment.apiUrl + 'kunden?limit=20&skip=0',
        querystring: '&search='
      },
    }
  }
];
