import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig } from '../../../models/field-config.interface';
import { Field } from '../../../models/field.interface';

@Component({
  selector: 'app-form-textarea',
  styles: ['mat-form-field {width: 90%}'],
  template: `
  <mat-form-field
    [formGroup]="group">
    <mat-label>{{ config.label }}</mat-label>
    <textarea matInput color="primary"
      [attr.type]="config.options.inputtype"
      [attr.placeholder]="config.placeholder"
      [formControlName]="config.name" [rows]="config.options.rows">
    </textarea>
  </mat-form-field>
`
})
export class FormTextareaComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
}
