export const CONFIG_FAHRZEUG_FORM_LAYOUT = {
  rows: [
    {
      cols: 2,
      names: ['hersteller', 'modell']
    },
    {
      cols: 3,
      names: ['erstzul', 'schluesselnr_2_1', 'schluesselnr_2_2'],
    },
    {
      cols: 1,
      names: ['fahrgestellnr'],
    },
    {
      cols: 3,
      names: ['kennzeichen', 'kmstand', 'hu_au'],
    },
    {
      cols: 1,
      names: ['kunde'],
    },
    {
      cols: 1,
      names: ['submit']
    }
  ]
};
