import {Component, OnInit, Input, OnChanges, Output, EventEmitter} from '@angular/core';
import { environment } from '@env/environment';
import { version } from '../../../../version';
import { AuthenticationService } from '@core/services/authentication.service';
import { User } from 'src/app/interfaces/user-interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  navbarOpen = false;
  ENV = environment;
  VERSION = version;

  sidenavExpanded = false;

  @Input() currentUser: any;
  @Output() changeExpandedState: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public authService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
  }

  toggleNavbar() {
    this.sidenavExpanded = !this.sidenavExpanded;
    this.changeExpandedState.emit(this.sidenavExpanded);
  }

  logoutUser() {
    this.authService.logoutUser().subscribe();
  }

  redirectTo(path) {
    this.router.navigate([path]);
  }
}
