/* Angular Dependencies */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

/* Custom modules */
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';

/* App Module declarations */
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { NavigationModule } from '@modules/navigation/navigation.module';


/* External dependencies */


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    NavigationModule,
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})

export class AppModule { }
