<div class="container-fluid" style="height: 100%;">
    
    <div class="row" *ngIf="fileName && !isLoading && !extractionResults">
        <!-- Button Zulassung erkennen! -->
        <button mat-flat-button color="primary" style="margin: 1em" (click)="startScan()">Scan starten</button>
        <button mat-flat-button color="primary" style="margin: 1em" (click)="onRemove()">Anderes Bild wählen</button>
    </div>
    <div class="main-zone-wrapper" *ngIf="isLoading || (!isLoading && !file)">
            <ngx-dropzone class="kfzschein-dropzone" *ngIf="!extractionResults && !isLoading && !file" (change)="onSelect($event)" [multiple]="false" accept="image/jpeg,image/jpg,image/png,application/pdf">
                <ngx-dropzone-label class="kfzschein-dropzone-label">
                    <mat-icon [inline]="true" class="material-icons-outlined dropzone-icon">cloud_upload</mat-icon>
                    <span>Fahrzeugschein hier fallen lassen oder <span style="color: steelblue;">klicken!</span></span>
                </ngx-dropzone-label>
            </ngx-dropzone>
            <div *ngIf="isLoading">
                <mat-progress-bar mode="determinate" [value]="progressBarValue"></mat-progress-bar>
                <p>{{ progressBarInfoText }}</p>
            </div>
    </div>

    <div class="row" *ngIf="formProcessed">
        <div class="col-12 col-md-6">
            <mat-card class="card-no-side-margin">
                <mat-card-title>
                    Datenbankergebnisse ({{ foundKunden.length }})
                </mat-card-title>
                <mat-card-content>
                    <div *ngFor="let kunde of foundKunden">
                        <div class="db-content-element-wrapper" (click)="selectedKunde = kunde" [ngClass]="{'db-content-element-selected': selectedKunde === kunde}">
                            <span>
                                {{ kunde.vorname ? kunde.vorname : '' }} {{ kunde.nachname ? kunde.nachname : kunde.firma ? kunde.firma : 'FEHLER' }}
                            </span>
                            <span>
                                {{ kunde.strasse ? kunde.strasse : '' }}
                            </span>
                            <span>
                                {{ kunde.plz ? kunde.plz : '' }} {{ kunde.ort ? kunde.ort : '' }}
                            </span>
                            <span>
                                -----
                            </span>
                        </div>
                    </div>
                    <div *ngIf="foundKunden.length === 0">
                        <h5>Kein Kunde vorhanden</h5>
                    </div>
                    <small class="not-in-list" *ngIf="!kundeNotInList" (click)="kundeNotInList=true">Wenn in der Liste nicht der richtige Kunde steht, klicke hier!</small>
                    <div *ngIf="foundKunden.length === 0 || kundeNotInList">
                        <button mat-flat-button color="primary" (click)="addNewKunde()">Kunde hinzufügen</button>
                    </div>
                </mat-card-content>
            </mat-card>
            
        </div>
        <div class="col-md-6 col-12">
            <mat-card class="card-no-side-margin">
                <mat-card-title>
                    Datenbankergebnisse ({{ foundFahrzeuge.length }})
                </mat-card-title>
                <mat-card-content>
                    <div *ngFor="let fahrzeug of foundFahrzeuge">
                        <div class="db-content-element-wrapper" (click)="selectedFahrzeug = fahrzeug" [ngClass]="{'db-content-element-selected': selectedFahrzeug === fahrzeug}">
                            <span>
                                {{ fahrzeug.hersteller || '' }} {{ fahrzeug.modell || '' }}
                            </span>
                            <span>
                                {{ fahrzeug.kennzeichen || '' }} | {{ fahrzeug.fahrgestellnr || '' }}
                            </span>
                            <span>
                                {{ fahrzeug.schluesselnr_2_1 || '' }} {{ fahrzeug.schluesselnr_2_2 || '' }}
                            </span>
                            <span>
                                -----
                            </span>
                        </div>
                    </div>
                    <div *ngIf="foundFahrzeuge.length === 0">
                        <h5>Kein Fahrzeug vorhanden</h5>
                        <button mat-flat-button color="primary" (click)="addNewFahrzeug()">Fahrzeug hinzufügen</button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <div *ngIf="(foundKunden.length === 1 && foundFahrzeuge.length === 1) || (selectedFahrzeug && selectedKunde)" class="col">
            <button mat-flat-button color="primary" (click)="confirmFetchedData()">Auswahl bestätigen und Abschließen</button>
            <br>
            <br>
        </div>
    </div>

    <div class="row" *ngIf="extractionResults && form">
        <div class="col-lg-4 col-md-2 col-12">
            <form [formGroup]="form">
                <div class="row mb-3">
                    <div class="col-12 mb-2 mt-2" *ngIf="!selectedKunde && !selectedFahrzeug">
                        <button mat-flat-button color="primary" (click)="processForm()" [disabled]="form.invalid">Daten prüfen</button>
                    </div>
                    <div class="col-12 col-lg-6" *ngFor="let field of formKeys">
                        <mat-card class="card-no-side-margin card-same-height">
                            <!-- #TODO: für kommendes Update
                            <div *ngIf="partialExtractionResults[field]">
                                <img [src]="partialExtractionResults[field]" />
                            </div>
                        -->
                            <mat-card-content>
                                <mat-form-field style="display: inline;">
                                    <input type="text" matInput [placeholder]="formKeyMap[field]" [formControlName]="field">
                                    <span [ngStyle]="extractionResultStyles(extractionResults.data[field]?.extractions[0].accuracy)" matSuffix>
                                        {{ (extractionResults.data[field]?.extractions[0].accuracy * 100 | number:'1.0-0') + '%'|| '' }}
                                    </span>
                                </mat-form-field>
                                <span *ngIf="suggestions[field].value">
                                    <!-- Vorschlag für Inhalt mit richtiger Validierung -->
                                    <mat-chip-list>
                                        <mat-chip [ngStyle]="{'background': suggestions[field].selected ? 'lightgreen' : ''}" (click)="selectSuggestion(field)">{{ suggestions[field].value }}</mat-chip>
                                    </mat-chip-list>
                                </span>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-lg-8 col-md-10 col-12">
            <div class="col-12">
                <div *ngIf="filePath && filePath !== '' && fileType !== 'application/pdf'" style="display: flex; justify-content: center;">
                    <img style="max-width: 80%; max-height: 60%" [src]="filePath" alt="Das Bild konnte nicht angezeigt werden (Unerwarteter Fehler)">
                </div>
                <div *ngIf="fileType === 'application/pdf'">
                    <ngx-extended-pdf-viewer [src]="file" useBrowserLocale="true" height="90vh"></ngx-extended-pdf-viewer>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="fileName && !isLoading && !extractionResults">
        <div class="col-12">
            <div *ngIf="filePath && filePath !== '' && fileType !== 'application/pdf'" style="display: flex; justify-content: center;">
                <img style="max-width: 80%; max-height: 60%" [src]="filePath" alt="Das Bild konnte nicht angezeigt werden (Unerwarteter Fehler)">
            </div>
            <div *ngIf="fileType === 'application/pdf'">
                <ngx-extended-pdf-viewer [src]="file" useBrowserLocale="true" height="90vh"></ngx-extended-pdf-viewer>
            </div>
        </div>
    </div>
</div>
