import { version } from '../version';

export const environment = {
  production: true,
  apiUrl: 'https://backend.kfzapp.smiwit.de/',
  authApiUrl: 'https://auth.kfzapp.smiwit.de/',
  ideaUrl: 'https://ideen.kfzapp.smiwit.de/',
  ideaApiUrl: 'https://backend.ideen.kfzapp.smiwit.de/',
  version,
};
