import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import {map, catchError, tap, take, flatMap} from 'rxjs/operators';
import {of, Observable, throwError, Subject, BehaviorSubject, lastValueFrom} from 'rxjs';
import { LocationService } from '@shared/services/location/location.service';
import { User } from 'src/app/interfaces/user-interface';

/**
 * Service eingeführt in Version 1.1
 * Status: Experimentell
 */

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  apiUrl = environment.apiUrl;
  authApiUrl = environment.authApiUrl;
  private currentUser$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public permissions$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(
    private http: HttpClient,
    private locationService: LocationService
  ) { }

  getCurrentUser(): Observable<any> {
      return this.currentUser$.asObservable();
  }

  getCurrentUserValue(): any {
      return this.currentUser$.value;
  }

  setCurrentUser(user: any) {
      this.currentUser$.next(user);
  }

  setCurrentUserPermissions(permissions: any) {
      this.permissions$.next(permissions);
      console.log(this.permissions$.value);
  }

  setUserFromStorage() {
      const user = this.userDataFromStore();
      this.currentUser$.next(user);
      this.permissions$.next(user.permission_set);
  }

  getUserProfile(id): Observable<any> {
    return this.http.get(this.authApiUrl + 'users/' + id)
        .pipe(
            map(
                user => {
                    return user;
                }
            )
        );
  }

  createUser(user: any) {
      return this.http.post(this.authApiUrl + 'users/', user, { headers: this.headers})
        .pipe(
            map((response: { message?: string }) => {
                return response.message;
            }),
            catchError(err => of(err.message))
        )
  }

  editUser(user: any) {
      return this.http.patch(this.authApiUrl + 'users/' + user.userId, user, { headers: this.headers })
      .pipe(
        map((response: { message?: string }) => {
            return response.message;
        }),
        catchError(err => of(err.message))
    )
  }

  deleteUser(id) {
      return this.http.delete(this.authApiUrl + 'users/' + id, {headers: this.headers})
      .pipe(
          map((response: any) => {
            return response
          }),
          catchError(err => of(err))
      )
  }

  login() {
    window.location.href = this.authApiUrl + 'sso/login?serviceURL=' + window.location.href.split('/').slice(0, 3).join('/');
  }

  getTokenFromSso(token: string): Observable<any> {
      return this.http.get<{appToken: string}>(this.apiUrl + 'apptoken')
          .pipe(
              flatMap(value => {
                  const headers = new HttpHeaders({
                      Authorization: `Bearer ${value.appToken}`
                  });
                  return this.http.get(this.authApiUrl + 'sso/verifytoken?ssoToken=' + token, { headers })
                      .pipe(
                          map((val: { message?: string, token?: string }) => {
                              if (!val.token) {
                                  throwError({ message: 'Fehler' });
                              }
                              return val.token;
                          }),
                          catchError(err => of(err))
                      );
              })
          );
  }

  verifyTokenLocal(token: string) {
      const headers = new HttpHeaders({
          Authorization: 'Bearer ' + token
      });
      return this.http.get(this.apiUrl + 'verifytoken', { headers })
          .pipe(
              map((response: { message: string, data: { permission_set: any, vorname: string, uid: string }}) => {
                  this.loginUser(token, response.data);
                  return response;
              }),
              catchError(err => of(err))
          );
  }

  renewAccessToken(): Observable<{ token: string}> {
    return this.http.get<{token: string}>(this.authApiUrl + 'sso/renewat')
          .pipe(
              tap((answer) => {
                  this.storeToken(answer.token);
              })
          );
  }

  loginUser(token: string, data: { permission_set: any, vorname: string, uid: string }) {
      this.storeToken(token);
      this.storeUserData(data);
      this.setCurrentUser(data);
      this.setCurrentUserPermissions(data.permission_set);
  }

  storeToken(token: string): void {
    localStorage.setItem('kfzapptoken', token);
  }

  getTokenFromStore(): string {
      return localStorage.getItem('kfzapptoken');
  }

  storeUserData(data: object) {
      localStorage.setItem('kfzappuser', JSON.stringify(data));
  }

  userDataFromStore() {
      return JSON.parse(localStorage.getItem('kfzappuser'));
  }

  getAllUsers() {
    return lastValueFrom(this.http.get(this.authApiUrl + 'users', {headers: this.headers}))
  }

  getOneUser(id) {
      return this.http.get(this.authApiUrl + 'users/' + id, {headers: this.headers })
      .pipe(
          map((response: any) => {
            return response.user
          }),
          catchError((err: any) => {
              return of(err)
          })
      )
  }

  get isLoggedIn() {
      if (this.checkTokenInStorage()) {
          return true;
      }
      return false;
  }

  userRolePermitted(roles: string[]): Observable<boolean> {
      const user = this.currentUser$.value;
      if (!user) {
          return of(false);
      }
      return this.http.get(this.authApiUrl + 'users/' + user.uid)
          .pipe(
              map(
                  (userResponse: any) => {
                      // #UPDATE 31.07.2021: userResponse!.user! hinzugefügt (Artikelliste funktionierte nicht richtig)
                      if (roles.includes(userResponse.user.permission_set)) {
                          return true;
                      }
                      return false;
                  }
              )
          );
  }
  clearStore() {
      localStorage.removeItem('kfzappuser');
      localStorage.removeItem('kfzapptoken');
  }

  checkTokenInStorage(): boolean {
      const token = localStorage.getItem('kfzapptoken');
      const user = localStorage.getItem('kfzappuser');
      if (token && user) {
          return true;
      }
      return false;
  }

  logoutUser() {
      return this.http.get(this.authApiUrl + 'sso/logout', { responseType: 'text' })
          .pipe(
              map(
                  response => {
                      console.log(response)
                      this.clearStore();
                      window.location.href = this.authApiUrl + 'sso/login?serviceURL=' + window.location.href.split('/').slice(0, 3).join('/');
                      return false;
                  }
              ),
              catchError((error, caught) => {
                console.log("Unerwarteter Fehler", error);
                return of(false)
              })
          );
  }

  generateResetCode(userId: string) {
      return this.http.get(this.authApiUrl + 'sso/pwreset/code/' + userId)
        .pipe(
            map(
                (response: any) => {
                    return response.reset_code;
                }
            )
        );
  }
}
