import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';
@Component({
  selector: 'app-form-button-chooser',
  template: `
    <div
      [formGroup]="group">
      <label>{{ config.label }}</label>
      <app-button-chooser color="primary"
        [choices]="config.options.choices"
        [formControlName]="config.name"></app-button-chooser>
    </div>
  `,
  styles: ['div { margin: 0.5em; }']
})
export class FormButtonChooserComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
}
