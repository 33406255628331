import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';

@Component({
  selector: 'dynamic-form-dialog',
  template: `
  <mat-dialog-content>
  <dynamic-form [config]="data.config.formConfig" [layout]="data.config.formLayoutConfig ? data.config.formLayoutConfig: null" [validation]="data.config.formValidationConfig ? data.config.formValidationConfig: null" [data]="data.data ? data.data: {}" (submitted)="onSubmit($event)" (changedValue)="onFormChange($event)"></dynamic-form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button type="button" mat-raised-button (click)="close()">Abbrechen</button>
    <button type="button"
      mat-raised-button color="primary"
      (click)="form.valid && form.form.dirty ? onSubmit(form.value): ''"
      [disabled]="!form.valid || !form.form.dirty"
      [matTooltip]="tooltipValue"
      matTooltipClass="tooltip-verlauf"
    >Speichern
    </button>
  </mat-dialog-actions>
  `,
  styles: []
})

export class DynamicFormDialogComponent {
  @ViewChild(DynamicFormComponent, {static: true}) form: DynamicFormComponent;

  tooltipValue: string;

  constructor(
    private dialogRef: MatDialogRef<DynamicFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onFormChange(event) {
    if (event.invalid) {
      this.tooltipValue = 'Ungültige Felder:\n';
      const controls = event.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          this.tooltipValue += '- ' + name + '\n';
        }
      }
    } else {
      this.tooltipValue = 'Speichern';
    }
  }

  onSubmit(value) {
    if (this.data.data ? this.data.data._id : false) {
      value._id = this.data.data._id;
    }
    this.dialogRef.close(value);
  }

  close() {
    this.dialogRef.close();
  }
}
