import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '../material.module';

import { DynamicFormModule } from '@shared/components/dynamic-form/dynamic-form.module';

import { WithLoadingPipe } from '@shared/pipes/with-loading-pipe';

import { FilterComponent } from '@shared/components/filter/filter.component';
import { VerlaufComponent } from '@shared/components/verlauf/verlauf/verlauf.component';
import { ProgressSpinnerDialogComponent } from '@shared/components/progress-spinner-dialog/progress-spinner-dialog.component';
import { SearchUniversalComponent } from '@shared/components/search-universal/search-universal.component';
import { FzscanUploadComponent } from './components/fzscan-upload/fzscan-upload.component';
import { DocumentViewComponent } from './components/document-view/document-view.component';
import { DocumentDetailComponent } from './components/document-detail/document-detail.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { ExportExcelDirective } from '@shared/directives/export-excel/export-excel.directive';
import { HasPermissionDirective } from '@shared/directives/has-permission.directive';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { SnackBarService } from './services/messages/snackbar.service';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DynamicFormModule,
    NgxDropzoneModule,
    NgxExtendedPdfViewerModule
  ],
  declarations: [
    ExportExcelDirective,
    VerlaufComponent,
    SearchUniversalComponent,
    FilterComponent,
    ProgressSpinnerDialogComponent,
    WithLoadingPipe,
    FzscanUploadComponent,
    DocumentViewComponent,
    DocumentDetailComponent,
    HasPermissionDirective
  ],
  exports: [
    FilterComponent,
    ExportExcelDirective,
    HasPermissionDirective,
    DynamicFormModule,
    VerlaufComponent,
    SearchUniversalComponent,
    ProgressSpinnerDialogComponent,
    WithLoadingPipe,
    FzscanUploadComponent,
    DocumentViewComponent,
    NgxExtendedPdfViewerModule,

    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  providers: [
    SnackBarService
  ]
})
export class SharedModule {}