import { Component, OnInit } from '@angular/core';
import { FahrzeugService } from '@core/http/fahrzeuge/fahrzeug.service';
import { take } from 'rxjs/operators';
import { FzscanAnswer } from '@interfaces/fzscan.interface';
import { FzscanService } from '@core/http/fzscan/fzscan.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { CustomValidators } from '@config/forms/customValidator/customValidator';
import { PATTERNS } from '@config/forms/customValidator/patterns';
import { KundeService } from '@core/http/kunden/kunde.service';
import { Fahrzeug, Kunde } from '@interfaces/kundenfahrzeug-interface';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CONFIG_FAHRZEUG_FORM_GLOBAL } from '@config/forms/fahrzeug/fahrzeug-form-global.config';
import { DynamicFormDialogComponent } from '../dynamic-form/containers/dynamic-form-dialog/dynamic-form-dialog.component';
import { CONFIG_KUNDE_FORM_GLOBAL } from '@config/forms/kunde/kunde-form-global.config';
import { SnackBarService } from '@shared/services/messages/snackbar.service';

@Component({
  selector: 'app-fzscan-upload',
  templateUrl: './fzscan-upload.component.html',
  styleUrls: ['./fzscan-upload.component.scss']
})
export class FzscanUploadComponent implements OnInit {
  
  public fileName: string;
  public fileType: string;
  public file: File;
  public filePath: string;

  public extractionResults: FzscanAnswer;
  public processNr: string;
  public partialExtractionResults: any;

  public isLoading: Boolean = false;
  public progressBarValue: number = 0;
  public progressBarInfoText: string;
  public formProcessed: Boolean = false;

  public kundeNotInList: Boolean = false;

  public form: FormGroup;
  public suggestions: object = {
    HSN: {
      value: '',
      selected: false
    },
    TSN: {
      value: '',
      selected: false
    },
    vin: {
      value: '',
      selected: false
    },
    ort: {
      value: '',
      selected: false
    },
    plz: {
      value: '',
      selected: false
    },
    strasse: {
      value: '',
      selected: false
    },
    vorname: {
      value: '',
      selected: false
    },
    name: {
      value: '',
      selected: false
    },
    kz: {
      value: '',
      selected: false
    },
    erstzul: {
      value: '',
      selected: false
    },
  }

  fzConfig;
  kdConfig;
  rawFzConfig = CONFIG_FAHRZEUG_FORM_GLOBAL;
  rawKdConfig = CONFIG_KUNDE_FORM_GLOBAL;

  public formKeys: string[] = [];
  public formKeyMap = {
    HSN: 'HSN(2.1)',
    TSN: 'TSN(2.2)',
    vin: 'Fahrgestellnummer',
    ort: 'Ort',
    plz: 'PLZ',
    strasse: 'Straße',
    vorname: 'Vorname',
    name: 'Nachname',
    kz: 'Kennzeichen',
    erstzul: 'Erstzulassung'
  }

  foundKunden: Kunde[] = [];
  foundFahrzeuge: Fahrzeug[] = [];
  selectedKunde: Kunde;
  selectedFahrzeug: Fahrzeug;

  constructor(
    private fahrzeugService: FahrzeugService,
    private fzscanService: FzscanService,
    private fb: FormBuilder,
    private kundeService: KundeService,
    private router: Router,
    public dialog: MatDialog,
    public snackBarService: SnackBarService
  ) { 
    this.fzConfig = _.cloneDeep(this.rawFzConfig);
    this.kdConfig = _.cloneDeep(this.rawKdConfig);
  }

  ngOnInit(): void {
  }

  onFileSelected(event): void {
    const fileData = event.target.files[0];
    if (!fileData) {
      this.fileName = null;
      this.filePath = null;
      this.file = null;
      this.fileType = null;
      return;
    }
    this.fileName = fileData.name;
    this.fileType = fileData.type;
    this.file = fileData;

    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    }
    reader.readAsDataURL(this.file);
  }

  setForm(): void {
    const data = _.cloneDeep(this.extractionResults).data;
    // Formular mit Werten aus Extraktion setzen, falls vorhanden
    this.form = this.fb.group({
      kz: [data.kz?.extractions[0]?.value, [CustomValidators.pattern(PATTERNS.kennzeichen), Validators.required]],
      vorname: [data.vorname?.extractions[0]?.value],
      name: [data.name?.extractions[0]?.value, Validators.required],
      strasse: [data.strasse?.extractions[0]?.value, Validators.required],
      plz: [data.plz?.extractions[0]?.value, [CustomValidators.pattern(PATTERNS.plz), Validators.required]],
      ort: [data.ort?.extractions[0]?.value, Validators.required],
      erstzul: [data.erstzul?.extractions[0]?.value, [CustomValidators.pattern(PATTERNS.erstzul), Validators.required]],
      HSN: [data.HSN?.extractions[0]?.value, [CustomValidators.pattern(PATTERNS.hsn), Validators.required]],
      TSN: [data.TSN?.extractions[0]?.value, [CustomValidators.pattern(PATTERNS.tsn), Validators.required]],
      vin: [data.vin?.extractions[0]?.value, [CustomValidators.pattern(PATTERNS.fin), Validators.required]],
    });
    this.formKeys = Object.keys(this.form.controls);
    // Falls leere oder falsche Felder existieren, sollen diese sofort angezeigt werden
    // Daher muss jedes Fomularfeld als "touched" markiert werden
    (<any>Object).values(this.form.controls).forEach((control: FormControl) => {
      control.markAsTouched();
    });

    // Die Bildschnipsel sollen separat vom Server abgeholt werden
    /* #TODO: Kommendes Update v2.8.3
    this.fzscanService.getPartialImages(this.processNr)
      .subscribe({
        next: (result) => {
          console.log(result);
          this.partialExtractionResults = result.partialImages;
        },
        error: (err) => {
          alert(err.message);
        }
      })*/
  }

  onSelect(event): void {
    const fileData = event.addedFiles[0];
    if (!fileData) {
      this.fileName = null;
      this.filePath = null;
      this.file = null;
      this.fileType = null;
      return;
    }
    this.fileName = fileData.name;
    this.fileType = fileData.type;
    this.file = fileData;

    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;
    }
    reader.readAsDataURL(this.file);
  }

  onRemove(): void {
    console.log("Remove");
    this.fileName = null;
    this.filePath = null;
    this.file = null;
    this.fileType = null;
  }

  startScan(): void {
    this.isLoading = !this.isLoading;
    this.progressBarInfoText = "Dokument wird zum kfzapp Server gesendet...";
    this.progressBarValue = 10;
    this.fahrzeugService.uploadImage(this.file, 'kfzschein')
      .pipe(take(1))
      .subscribe({
        next: (result: {processNr: string, isError: boolean, message: string}) => {
          if (result.isError) {
            this.progressBarInfoText = result.message;
            this.isLoading = !this.isLoading;
            return;
          }
          this.processNr = result.processNr;
          this.progressBarValue = 40;
          this.progressBarInfoText = "Dokument wird zum Scannen weitergeleitet...";
          this.fahrzeugService.uploadToKonfuzio(result.processNr)
            .pipe(take(1))
            .subscribe({
              next: (konfResult: { isError: boolean, message: string}) => {
                if (result.isError) {
                  this.progressBarInfoText = result.message;
                  this.isLoading = !this.isLoading;
                  return;
                }
                this.progressBarValue = 70;
                this.progressBarInfoText = "Dokument wird ausgelesen...";
                this.fahrzeugService.getExtractionFromKonfuzio(result.processNr)
                .pipe(take(1))
                .subscribe({
                  next: (konfExtractionResult: any|{isError: boolean, message: string}) => {
                    console.log(konfExtractionResult);
                    if (konfExtractionResult.isError) {
                      this.progressBarInfoText = konfExtractionResult.message;
                      this.isLoading = !this.isLoading;
                      return;
                    }
                    this.progressBarValue = 100;
                    this.progressBarInfoText = "Dokument wurde erfolgreich ausgelesen...";
                    this.extractionResults = konfExtractionResult;
                    this.setForm();
                    this.isLoading = !this.isLoading;
                    this.processSuggestions();
                  }
                })
              }
            })
        }
      });
    // Bild hochladen (fahrzeugService.uploadImage)
    // Nach Antwort in Konfuzio hochladen (getExtraction)
    // Nach Antwort die Ergebnisse der extraction von konfuzio holen
    // Nach Antwort Felder mit Wahrscheinlichkeiten darstellen
    // Abgleich der Daten mit Datenbank und Vorschläge bei vorhandenen Kunden/Fahrzeugen machen
  }

  selectSuggestion(fieldName) {
    this.suggestions[fieldName].selected = !this.suggestions[fieldName].selected;
    this.form.controls[fieldName].setValue(this.suggestions[fieldName].selected ? this.suggestions[fieldName].value : this.extractionResults.data[fieldName].extractions[0].value);
    this.suggestions[fieldName].selected ? this.form.controls[fieldName].disable() : this.form.controls[fieldName].enable();
  }

  processSuggestions(): void {
    const results = this.extractionResults.data;
    for (let key in results) {
      if (this.form.controls[key]) {
        if (results[key]) {
          if (key === 'kz') {
            let str = results[key].extractions[0].value.trim().replace(/  +/g, ' ').replace(/\*/g, '');
            let splitted = str.split(/([ \-]+)|(\d+)/).filter(Boolean).filter(el => el.trim().length > 0 && el.trim() !== '-');
            if (splitted.length === 3) {
              this.suggestions[key].value = `${splitted[0]}-${splitted[1]} ${splitted[2]}`;
            }
          } else if (key === 'vin') {
            let str = results[key].extractions[0].value.trim().replace(/[OQ]/g, '0').replace(/I/g, '1').replace(' ', '');
            if (str !== results[key].extractions[0].value) {
              this.suggestions[key].value = str;
            }
          } else if (key === 'erstzul') {
            let str = results[key].extractions[0].value.trim().replace(/ /g, '');
            let splitted = str.split('.');
            if (splitted.length === 3) {
              if (splitted[2].length < 4) {
                if (splitted[2].length === 2) {
                  if (parseInt(splitted[2]) <= parseInt(new Date().getFullYear().toString().substring(2))) {
                    splitted[2] = '20' + splitted[2];
                  } else {
                    splitted[2] = '19' + splitted[2];
                  }
                  this.suggestions[key].value = `${splitted[0]}.${splitted[1]}.${splitted[2]}`;
                }
              } else {
                if (splitted[2].length === 4) {
                  if (str !== results[key].extractions[0].value) {
                    this.suggestions[key].value = str;
                  }
                }
              }
            }
          } else if (key === 'vorname' || key === 'name' || key === 'strasse' || key === 'ort') {
            let str: string = results[key].extractions[0].value.trim().replace(/  +/g, ' ');
            str = str.toLowerCase();
            let splitted = str.split(' ');
            let newVals = [];
            if (splitted) {
              for (let el of splitted) {
                if (isNaN(parseInt(el))) {
                  el = el.charAt(0).toUpperCase() + el.substring(1);
                }
                newVals.push(el);
              }
              str = newVals.join(' ');
              let minusIndices = [];
              for (let i = 0; i < str.length; i++) {
                if (str.charAt(i) === '-') {
                  minusIndices.push(i);
                }
              }
              if (minusIndices.length > 0) {
                for (let i = 0; i < minusIndices.length; i++) {
                  str = str.substring(0, minusIndices[0] + 1) + str.charAt(minusIndices[0] + 1).toUpperCase() + str.substring(minusIndices[i] + 2);
                }
              }
            }
            if (str !== results[key].extractions[0].value) {
              this.suggestions[key].value = str;
            }
          } else if (key === 'TSN' || key === 'HSN') {
            let str: string = results[key].extractions[0].value.trim().replace(' ', '');
            if (str !== results[key].extractions[0].value) {
              this.suggestions[key].value = str;
            }
          }
        }
      }
    }
  }

  processForm(): void {
    const formVal = this.form.getRawValue();
    this.kundeService.getAll({ nachname: formVal.name, vorname: formVal.vorname })
      .pipe(take(1))
      .subscribe(result => {
        if (result?.length === 0 || !result) {
          this.kundeService.getAll({ search: formVal.name })
            .pipe(take(1))
            .subscribe(resultInner => {
              this.foundKunden = [...resultInner];
              if (this.foundKunden.length === 1){
                this.selectedKunde = this.foundKunden[0];
              }
              this.formProcessed = true;
            }, errorInner => {
              alert('Es ist ein Fehler aufgetreten bei der Suche nach einem passenden Kunden');
            });
        } else {
          this.foundKunden = [...result];
          if (this.foundKunden.length === 1){
            this.selectedKunde = this.foundKunden[0];
          }
          this.formProcessed = true;
        }
      }, error => {
        alert('Es ist ein Fehler aufgetreten bei der Suche nach einem passenden Kunden');
      });
    this.fahrzeugService.getAll({ kennzeichen: formVal.kz, fahrgestellnr: formVal.vin })
      .pipe(take(1))
      .subscribe(result => {
        if (result.length === 0) {
          this.fahrzeugService.getAll({ fahrgestellnr: formVal.vin })
            .pipe(take(1))
            .subscribe(resultInner => {
              this.foundFahrzeuge = [...resultInner];
              if (this.foundFahrzeuge.length === 1){
                this.selectedFahrzeug = this.foundFahrzeuge[0];
              }
            }, errorInner => {
              console.log(errorInner);
            })
        } else {
          this.foundFahrzeuge = [...result];
          if (this.foundFahrzeuge.length === 1){
            this.selectedFahrzeug = this.foundFahrzeuge[0];
          }
        }
        
      }, error => {
        alert('Es ist ein Fehler aufgetreten bei der Suche nach einem passenden Fahrzeug');
      });
  }

  addNewKunde(): void {
    const dialogConfig = new MatDialogConfig();

    const fv = this.form.getRawValue();

    const formMap = {
      vorname: fv.vorname,
      nachname: fv.name,
      strasse: fv.strasse,
      plz: fv.plz,
      ort: fv.ort
    }

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      config: this.kdConfig,
      data: formMap
    };
    const dialogRef = this.dialog.open(DynamicFormDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.kundeService.create(result)
          .pipe(take(1))
          .subscribe(result => {
            this.snackBarService.openSnackBar(result.message, 'OK', { duration: 2500 });
            this.selectedKunde = result.kunde;
            this.foundKunden = [this.selectedKunde];
          }, error => {
            alert('Es ist ein Fehler beim Anlegen des Fahrzeugs aufgetreten');
          })
      }
    });
  }

  addNewFahrzeug(): void {
    if (!this.selectedKunde) {
      alert('Bitte erst Kunde auswählen oder anlegen');
      return;
    }

    const dialogConfig = new MatDialogConfig();
    const fv = this.form.getRawValue();
    
    const formMap = {
      kennzeichen: fv.kz,
      erstzul: fv.erstzul,
      schluesselnr_2_1: fv.HSN,
      schluesselnr_2_2: fv.TSN,
      fahrgestellnr: fv.vin,
      kunde: this.selectedKunde
    }

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      config: this.fzConfig,
      data: formMap
    };
    const dialogRef = this.dialog.open(DynamicFormDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fahrzeugService.create(result)
          .pipe(take(1))
          .subscribe(result => {
            this.snackBarService.openSnackBar(result.message, 'OK', { duration: 2500 });
            this.selectedFahrzeug = result.fahrzeug;
            this.foundFahrzeuge = [this.selectedFahrzeug];
          }, error => {
            alert('Es ist ein Fehler beim Anlegen des Fahrzeugs aufgetreten');
          })
      }
    });
  }

  confirmFetchedData(): void {
    this.fahrzeugService.addConnectedEntity(this.processNr, this.selectedFahrzeug._id)
      .pipe(take(1))
      .subscribe(result => {
        alert('Abgeschlossen! Du wirst zum Fahrzeug weitergeleitet.');
        this.router.navigate(['fahrzeuge', this.selectedFahrzeug._id]);
      }, error => {
        alert('Es ist ein Fehler beim Speichern des Fahrzeugscheins im Fahrzeug aufgetreten!');
      })
  }

  public extractionResultStyles(accuracy: number): object {
    return {
      'color': accuracy > 0.8 ? 'green' : accuracy > 0.7 ? 'orange' : 'red'
    }
  }
}
