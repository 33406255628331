import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/field-config.interface';

@Component({
  selector: 'form-button',
  styleUrls: ['./form-button.component.scss'],
  template: `
   <div
      class="dynamic-field form-button"
      [formGroup]="group">
      <button mat-raised-button color="primary" type="submit" [disabled]="config.options.disable ? !(group.valid && group.dirty) : false">
        {{ config.label }}
      </button>
    </div>
  `,
})
export class FormButtonComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
}
