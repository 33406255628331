<div class="row" *ngIf="choices.length < 3">
<div class="col-6 variable-col" *ngFor="let choice of choices">
    <span *ngIf="choice === 'ok'">
        <button [ngClass]="{'active': value === choice}" class="btn btn-outline-success btn-block" (click)="changeValue(choice)">
            {{ choice }}
        </button>
    </span>
    <span *ngIf="choice === 'ja'">
        <button [ngClass]="{'active': value === true}" class="btn btn-outline-success btn-block" (click)="changeValue(true)">
            {{ choice }}
        </button>
    </span>
    <span *ngIf="choice === 'nok'">
        <button [ngClass]="{'active': value === choice}" class="btn btn-outline-danger btn-block" (click)="changeValue(choice)">
            {{ choice }}
        </button>
    </span>
    <span *ngIf="choice === 'nein'">
        <button [ngClass]="{'active': value === false}" class="btn btn-outline-danger btn-block" (click)="changeValue(false)">
            {{ choice }}
        </button>
    </span>
    <span *ngIf="choice == 'erledigt'">
        <button [ngClass]="{'active': value === choice}" class="btn btn-outline-success btn-block" (click)="changeValue(choice)">
            {{ choice }}
        </button>
    </span>
    <span *ngIf="choice == 'brems'">
        <button [ngClass]="{'active': value === '&lt;1%'}" class="btn btn-outline-success btn-block" (click)="changeValue('&lt;1%')">
            &lt;1 %
        </button>
        <button [ngClass]="{'active': value === '1%'}" class="btn btn-outline-success btn-block" (click)="changeValue('1%')">
            1 %
        </button>
        <button [ngClass]="{'active': value === '2%'}" class="btn btn-outline-warning btn-block" (click)="changeValue('2%')">
            2 %
        </button>
        <button [ngClass]="{'active': value === '3%'}" class="btn btn-outline-danger btn-block" (click)="changeValue('3%')">
            3 %
        </button>
        <button [ngClass]="{'active': value === '&gt;3%'}" class="btn btn-outline-danger btn-block" (click)="changeValue('&gt;3%')">
            &gt;3 %
        </button>
    </span>
    <span *ngIf="choice == 'alu' || choice == 'stahl'">
        <button [ngClass]="{'active': value == choice}" class="btn btn-outline-secondary btn-block" (click)="changeValue(choice)">
            {{ (choice == 'alu') ? 'Leichtmetall': 'Stahl' }}
        </button>
    </span>
</div>
</div>

<div class="row" *ngIf="choices.length >= 3">
    <div class="col-4 variable-col" *ngFor="let choice of choices">
        <span *ngIf="choice == 'so' || choice == 'wi' || choice == 'gj'">
            <button [ngClass]="{'active': value == choice}" class="btn btn-outline-secondary btn-block" (click)="changeValue(choice)">
                {{ choice == 'so' ? 'Sommer': (choice == 'wi' ? 'Winter': 'Allwetter') }}
            </button>
        </span>
    </div>
</div>