import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';

import { NavbarComponent } from './navbar/navbar.component';
import { NotificationComponent } from './notification/notification.component';
import { NotificationElementComponent } from './notification/notification-element/notification-element.component';
import { SidenavComponent } from './sidenav/sidenav.component';

@NgModule({
  declarations: [
    NavbarComponent,
    NotificationComponent,
    NotificationElementComponent,
    SidenavComponent
  ],
  imports: [
    SharedModule,
    RouterModule
  ],
  exports: [
    NavbarComponent,
    SidenavComponent
  ]
})
export class NavigationModule { }