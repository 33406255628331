import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FzscanUploadComponent } from '@shared/components/fzscan-upload/fzscan-upload.component';

import { AuthGuard } from './core/guards/auth/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'dashboard', loadChildren: () => import('@modules/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard]},
  { path: 'kunden', loadChildren: () => import('@modules/kunden/kunden.module').then(m => m.KundenModule), canActivate: [AuthGuard]},
  { path: 'fahrzeuge', loadChildren:
        () => import('@modules/fahrzeuge/fahrzeuge.module').then(m => m.FahrzeugeModule), canActivate: [AuthGuard] },
  { path: 'inspektion', loadChildren: () => import('@modules/inspektion/inspektion.module').then(m => m.InspektionModule),
    canActivate: [AuthGuard] },
  { path: 'check', loadChildren: () => import('@modules/check/check.module').then(m => m.CheckModule),
    canActivate: [AuthGuard] },
  { path: 'reifenlager', loadChildren: () => import('@modules/reifenlager/reifenlager.module').then(m => m.ReifenlagerModule),
    canActivate: [AuthGuard] },
  { path: 'usermanagement', loadChildren: () => import('@modules/user-management/user-management.module')
    .then(m => m.UserManagementModule), canActivate: [AuthGuard]},
  { path: 'admin', loadChildren: () => import('@modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'artikelliste', loadChildren: () => import('@modules/artikel/artikel.module')
      .then(m => m.ArtikelModule), canActivate: [AuthGuard]
  },
  {
    path: 'fzscan',
    component: FzscanUploadComponent
  }
  // { path: 'kummerkasten', component: KummerkastenComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' }),
  ],
  exports: [
    RouterModule,
  ]
})

export class AppRoutingModule { }
