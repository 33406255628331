import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from '@core/services/authentication.service';
import {Observable, throwError, of, Subject} from 'rxjs';
import { switchMap, tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

/**
 * Eingeführt in Version 1.1
 * Angepasst in Version 2.4.6
 * Status: Live Test
 */

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private refreshTokenInProgress = false;

    private tokenRefreshedSource = new Subject<void>();
    private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

    constructor(public authService: AuthenticationService, private router: Router) { }

    addAuthHeader(request) {
        const authHeader = this.authService.getTokenFromStore();
        if (authHeader) {
            return request.clone({
                setHeaders: {
                    Authorization: `Bearer ${authHeader}`
                }
            });
        }
        return request;
    }

    refreshToken(): Observable<any> {
        if (this.refreshTokenInProgress) {
            return new Observable(observer => {
                this.tokenRefreshed$.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;

            return this.authService.renewAccessToken().pipe(
                tap(() => {
                    this.refreshTokenInProgress = false;
                    this.tokenRefreshedSource.next();
                }),
                catchError((err) => {
                    this.refreshTokenInProgress = false;
                    this.logout();
                    return of(err);
                })
            );
        }
    }

    logout(): void {
        this.authService.logoutUser().subscribe();
    }

    handleResponseError(error, request?, next?) {
        if (error.status === 400) {
            // Show message
        }
        // Invalid token error
        else if (error.status === 401) {
            return this.refreshToken().pipe(
                switchMap(() => {
                    request = this.addAuthHeader(request);
                    return next.handle(request);
                }),
                catchError(e => {
                    if (e.status !== 401) {
                        return this.handleResponseError(e);
                    } else {
                        this.logout();
                    }
                }));
        }

        // Access denied error
        else if (error.status === 403) {
            // Show message
            alert(error.error.message);
            return of(null);
        }

        // Server error
        else if (error.status === 500) {
            // Show message
        }

        // Maintenance error
        else if (error.status === 503) {
            // Show message
            // Redirect to the maintenance page
        }
        return throwError(error);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({
            withCredentials: true
        });
        if ((req.url.indexOf('verifytoken') !== -1 && req.url.indexOf('sso') !== -1) || req.url.indexOf('renewat') !== -1) {
            return next.handle(req);
        }
        req = this.addAuthHeader(req);
        return next.handle(req)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    return this.handleResponseError(err, req, next);
                })
            ) as Observable<HttpEvent<any>>;
    }
}
