import { Component, Input } from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-button-chooser',
  templateUrl: './button-chooser.component.html',
  styleUrls: ['./button-chooser.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ButtonChooserComponent,
    multi: true,
  }]
})
export class ButtonChooserComponent implements ControlValueAccessor {

  @Input() choices: string[];

  value: any;
  private propagateChange = Function.prototype;
  private propagateTouched = Function.prototype;

  public writeValue(value: any) {
    this.value = value;
  }

  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.propagateTouched = fn;
  }

  changeValue(value: any) {
    this.value = value;
    this.propagateChange(this.value);
    this.propagateTouched();
    return false;
  }

}
