<button mat-icon-button class="dropdown notification-container" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

    <mat-icon [matBadgeHidden]="notificationsCount === 0" [matBadge]="notificationsCount" matBadgeColor="warn">notifications</mat-icon>

    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" (click)="$event.stopPropagation()">
        <div class="notification-dropdown-container">
            <div class="notification-dropdown-heading">
                <span>{{ notificationsCount }} Benachrichtigungen</span>
                <mat-icon class="notification-dropdown-reload" (click)="reloadNotifications($event)">cached</mat-icon>
            </div>
            <mat-divider></mat-divider>
            <div *ngIf="notificationsCount === 0">
                Nichts neues...
                <mat-divider></mat-divider>
            </div>
            <div class="notification-list">
                <div *ngFor="let notification of notifications$ | async">
                    <app-notification-element [notification]="notification" (triggerReload)="reloadNotifications()"></app-notification-element>
                </div>
            </div>
            <div class="text-center" *ngIf="notificationsCount > 1">
                <span class="delete-text" (click)="deleteAllNotifications($event)">Alle löschen</span>
                <mat-divider></mat-divider>
            </div>
            <div class="text-center">
                <a class="delete-text" [href]="ideaUrl" target="_blank">Zum Ideenboard</a>
            </div>
        </div>
    </div>
</button>
