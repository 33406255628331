import {Component, OnInit} from '@angular/core';
import { version } from '../version';
import { AuthenticationService } from '@core/services/authentication.service';
import { LocationService } from '@shared/services/location/location.service';
import { environment } from '@env/environment';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  VERSION = version;
  prod = environment.production;
  isExpanded = false;

  destroySubject$: Subject<void> = new Subject();
   constructor(
     public authService: AuthenticationService, locationService: LocationService
   ){
     locationService.loadRouting();
   }

  ngOnInit() {
       this.authService.getCurrentUser().pipe(takeUntil(this.destroySubject$))
           .subscribe(user => {
               if (!user && this.authService.checkTokenInStorage()) {
                   this.authService.setUserFromStorage();
               }
           });
  }
}
